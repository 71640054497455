import './style.css';
import {Map, View} from 'ol';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import {OSM, Vector as VectorSource} from 'ol/source.js';
import {toLonLat, fromLonLat, transformExtent, getPointResolution} from 'ol/proj.js';
import {Draw, Modify, Snap} from 'ol/interaction.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import {Circle as CircleStyle, Fill, Stroke, Style, Icon, Text} from 'ol/style.js';
import Circle from "ol/geom/Circle";
import Geolocation from 'ol/Geolocation.js';
import Point from 'ol/geom/Point.js';
import Feature from 'ol/Feature.js';
import Static from "ol/source/ImageStatic";
import ImageLayer from "ol/layer/Image";
import {DragPan, MouseWheelZoom, defaults} from 'ol/interaction.js';
import {platformModifierKeyOnly} from 'ol/events/condition.js';

const centerWM = fromLonLat([5.0899, 51.5973]);

function flowerStyleF(feature){
	//const lat = toLonLat(feature.getGeometry().getCoordinates()).substring(0,9);
	const coords = toLonLat(feature.getGeometry().getCoordinates()).toString(); 
	const lat = coords.substr(coords.indexOf(",")+1, 9);
	const lon = coords.substring(0,9);
	const tLabel = "r: " + feature.get("radius") + "\nlat: " + lat + "\nlon: " + lon ;
	return new Style({
        fill: new Fill({
          color: "rgba(243, 250, 0, 1)"
        }),
        geometry: function (feature) {
        	const coord = feature.getGeometry().getCoordinates();
        	const rad = feature.get("radius") / getPointResolution('EPSG:3857', 1, coord);
			return new Circle(coord, rad);
        },
        text: new Text({
        	text: tLabel,
        }),
	})
}
const flowerLayer = new VectorLayer({
	source: new VectorSource({
		format: new GeoJSON(),
    	url: "flowers.geojson",
	}),
	style: flowerStyleF,
})

const boundsLayer = new VectorLayer({
  source: new VectorSource({
    format: new GeoJSON(),
    url: "nb_bounds.geojson",
  }),
});

var cnv = document.createElement('canvas');
var ctx = cnv.getContext('2d');
var img = new Image();
img.src = 'bifs-bg2.png';
img.onload = function() {
  var pattern = ctx.createPattern(img, 'repeat');
  boundsLayer.setStyle(new Style({
    fill: new Fill({
      color: pattern
    })
  }));
};


const vaseLayer = new VectorLayer({
  source: new VectorSource({
    format: new GeoJSON(),
    url: "vase.geojson",
  }),
  style: new Style({
		fill: new Fill({
          color: "rgba(180, 255, 16, 1)"			
		})
  })
});

const pathLayer = new VectorLayer({
  source: new VectorSource({
    format: new GeoJSON(),
    url: "paths.geojson",
  }),
  style: new Style({
		fill: new Fill({
          color: "rgba(180, 255, 16, 1)"			
		})
  })
});

const source = new VectorSource();
const vector = new VectorLayer({
  source: source,
  style: {
    'fill-color': 'rgba(255, 255, 255, 0.2)',
    'stroke-color': '#ffcc33',
    'stroke-width': 2,
    'circle-radius': 7,
    'circle-fill-color': '#ffcc33',
  },
});

var hyperExtent = transformExtent([5.08871, 51.59642, 5.09155, 51.59785], 'EPSG:4326', 'EPSG:3857'); 
const hyperSource = new Static({
	url: 'ilse_layer_v003.png',
	imageExtent: hyperExtent,
});

const hyperlayer = new ImageLayer({
	source: hyperSource,
	extent: hyperExtent,
    opacity: 1,
});
  
const view = new View({
    center: centerWM,
    zoom: 18.5,
});

const map = new Map({
  interactions: defaults({dragPan: false, mouseWheelZoom: false}).extend([
    new DragPan({
      condition: function (event) {
        return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
      },
    }),
    new MouseWheelZoom({
      condition: platformModifierKeyOnly,
    }),
  ]),
  target: 'map',
  layers: [
    new TileLayer({
      source: new OSM()
    }), 
  ],
  view: view,
});
map.addLayer(hyperlayer);

const modify = new Modify({source: source});
map.addInteraction(modify);

let draw, snap; // global so we can remove them later
const typeSelect = document.getElementById('type');

function addInteractions() {
  draw = new Draw({
    source: source,
    type: typeSelect.value,
  });
  map.addInteraction(draw);
  snap = new Snap({source: source});
  map.addInteraction(snap);
}


const geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view.getProjection(),
});

function el(id) {
  return document.getElementById(id);
}

el('track').addEventListener('change', function () {
  geolocation.setTracking(this.checked);
});

// update the HTML page when the position changes.
geolocation.on('change', function () {
  el('accuracy').innerText = geolocation.getAccuracy() + ' [m]';
  el('altitude').innerText = geolocation.getAltitude() + ' [m]';
  el('altitudeAccuracy').innerText = geolocation.getAltitudeAccuracy() + ' [m]';
  el('heading').innerText = geolocation.getHeading() + ' [rad]';
  el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
});

// handle geolocation error.
geolocation.on('error', function (error) {
  const info = document.getElementById('info');
  info.innerHTML = error.message;
  info.style.display = '';
});

const accuracyFeature = new Feature();
geolocation.on('change:accuracyGeometry', function () {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

const positionFeature = new Feature();
positionFeature.setStyle(
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#3399CC',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 2,
      }),
    }),
  }),
);

geolocation.on('change:position', function () {
  const coordinates = geolocation.getPosition();
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
});

new VectorLayer({
  map: map,
  source: new VectorSource({
    features: [accuracyFeature, positionFeature],
  }),
});
